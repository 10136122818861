import React, { useContext, useState } from "react"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Card from "@components/Cards/Card.jsx"
import { CardContent, CardActions } from "@material-ui/core"
import Link from "@components/Buttons/Link.jsx"
import { makeComponentStyles } from "@components/mixins"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../../heplers.js"
import { Pagination } from "@material-ui/lab"
import Seo from "@components/seo"
import { graphql } from "gatsby"

const styles = ({ palette }) => ({
  date: {
    color: palette.text.muted,
    fontSize: "14px"
  },
  title: {
    fontSize: "18px",
    paddingBottom: "12px"
  },
  link: {
    justifyContent: "flex-end",
    padding: "8px 16px",
    height: "100%",
    alignItems: "end"
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  description: {
    overflow: "hidden",
    textOverflow: "clip",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3
  },
  pagination: {
    margin: "25px 0"
  }
})

function Index({ pageContext, data }) {

  const intl = useIntl()

  const {
    date,
    title,
    link,
    card,
    description,
    pagination
  } = makeComponentStyles(styles)

  const payload = pageContext.payload.map(node => ({
    id: node.id,
    node: getStrapiContentByLang(node.nodes, intl.locale)
  }))

  const getItemsByPageNumber = number =>
    payload.slice((number - 1) * pageSize, number * pageSize)

  const pageSize = 12
  const [page, setPage] = useState(1)
  const [currentItems, setCurrentItems] = useState(
    getItemsByPageNumber(1)
  )

  const handlerPagination = (event, value) => {
    setPage(value)
    setCurrentItems(
      getItemsByPageNumber(value)
    )
  }

  const {
    AdsSEO
  } = getStrapiContentByLang(data.allStrapiSettings.edges, intl.locale)

  return (
    <div>
      <Seo title={AdsSEO?.TitleSEO}
           description={AdsSEO?.DescriptionSEO}
           keywords={AdsSEO?.KeywordsSEO.split(",")}
      />
      <Layout>
        <Container>
          <MainWrap>
            <MainWrap.Header>
              <Typography component={"h1"} variant={"h2"}>{intl.formatMessage({ id: "ads" })}</Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <Grid container spacing={2}>
                {
                  currentItems.map(({ node }, key) => (
                    <Grid item md={4} lg={3} xs={12} key={key}>
                      <Card className={card} radius={0}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={date}>{node.published_at}</Typography>
                          <Typography className={title}>{node.Title}</Typography>
                          <Typography className={description}
                                      style={{ fontSize: "14px" }}>{node.ShortDescription}</Typography>
                        </CardContent>
                        <CardActions className={link}>
                          <Link to={`/ads/${node.strapiId}`}>{intl.formatMessage({ id: "detail" })}</Link>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid>
              <Pagination count={Math.ceil(payload.length / pageSize)}
                          page={page}
                          onChange={handlerPagination}
                          color={"primary"}
                          className={pagination}
              />
            </MainWrap.Body>
          </MainWrap>
        </Container>
      </Layout>
    </div>
  )
}

export default Index

export const pageQuery = graphql`{
  allStrapiSettings {
    edges {
      node {
        AdsSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
        locale
      }
    }
  }
}`
